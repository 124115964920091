@import "_generic";

.align-left {
  text-align: left !important;
}

.align-right {
  text-align: right !important;
}

.alert-info {
  background-color: $colour-blue !important;
}

td {
  border: none !important;

  span, button {
    vertical-align: text-top !important;
  }
}

.rotate-90 {
  transform: rotate(90deg);
}

.success-colour {
  color: $colour-green;
}

.black-colour {
  color: $colour-black;
}

.danger-colour {
  color: $colour-red;
}

.warning-colour {
  color: $colour-orange;
}

.white-colour {
  color: $colour-white;
}

@keyframes spinner-border {
  to { transform: rotate(360deg); }
}

.spinner-border{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

.section-title {
  border-top: 1px solid $colour-white-darker;
  border-bottom: 1px solid $colour-white-darker;
  background-color: $colour-white-dark;
  padding: 5px 10px;
}

.section-description {
  .material-icons,
  .material-icons-outlined {
    font-size: 14px;
  }
}

.btn {
  &.btn-sm {
    .material-icons,
    .material-icons-outlined {
      vertical-align: bottom;
    }
  }
}

.btn-hover {
  &:hover {
    background-color: $colour-white-darker;
  }
}

.btn-blue {
  background-color: $colour-blue;
  color: $colour-white;
  width: 100px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  text-align: left;

  .label {
    margin-right: 20px;
    vertical-align: top;
  }

  .material-icons,
  .material-icons-outlined {
    float: right;
  }
}

.wrapper {
  &.black {
    background-color: $colour-black;
  }
}

.blue {
  color: $colour-blue;
}

.navbar {
  .notification-pane {
    .notification {
      right: 30px;
    }

    .dropdown-toggle {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    a {
      display: flex !important;
      padding: 10px !important;
      cursor: default;

      &.new {
        border-left: 1px solid $colour-red;
      }

      .info {
        width: 350px;
        float: left;

        .message {
          font-size: 12px;
          white-space: normal;
        }

        .date {
          font-size: 10px;
          color: $colour-gray-light;

          padding: 0px;
        }
      }

      .clear {
        float: right;
        margin-left: 10px;
        cursor: pointer;

        .material-icons {
          font-size: 16px;
        }
      }

      &:hover {
        .message {
          color: $colour-white !important;
        }

        .date {
          color: $colour-gray-lighter !important;
        }
      }

    }
  }

  .dropdown-menu {
    li {
      cursor: pointer;

      .dropdown-item {
        font-size: 0.9rem;
        padding-left: 15px;
        padding-right: 15px;

        .material-icons-outlined {
          margin-right: 5px;
          font-size: 18px;
        }
      }

      &:hover {
        .dropdown-item {
          color: $colour-white;
        }
      }
    }
  }
}

.login-container {
  .login-box {
    background-color: $colour-white;
    padding: 4% 10%;
    margin-top: 30%;
  }

  .login-header {
    display: block;
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 15rem;
      padding: 2rem;
    }
  }

  .login-input {
    text-align: center;
    background-color: $colour-blue-lighter;
    color: $colour-black;

    &::placeholder {
      color: $colour-gray;
    }
  }
}

details-box {
  display: flex !important;

  .details-box {
    &.no-shadow {
      box-shadow: none;
    }

    .card-image {
      padding-bottom: 0px !important;
      color: $colour-blue;

      :first-child {
        font-size: 90px;
      }
    }

    .card-body {
      padding-top: 0px !important;
      font-size: 22px;

      .card-number {
        color: $colour-blue;
        font-size: 52px;
        font-weight: lighter;
        margin-top: 0px;
        padding-top: 0px;

        .material-icons {
          font-size: 40px;
        }
      }
    }

    .card-footer {
      .stats {
        i {
          margin-right: 5px;
        }

        div {
          .material-icons {
            font-size: 18px !important;
            vertical-align: middle;
            margin: 0;
            cursor: default;
          }

          .fa {
            font-size: 18px;
            vertical-align: middle;
            margin: 0;
            cursor: default;
          }

          .text {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

chart-box {
  display: flex !important;

  .chart-box {
    &.blue {
      background-color: $colour-blue;

      .material-icons,
      .material-icons-outlined {
        color: $colour-white;
      }

      .footer {
        color: $colour-blue-light;

        .bold {
          color: $colour-blue-lighter;
        }
      }
    }

    &.white {
      background-color: $colour-white;
    }

    &.no-shadow {
      box-shadow: none;
    }

    .footer {
      padding: 10px;
      font-size: 22px;

      .bold {
        padding-right: 5px;
      }
    }

    .material-icons,
    .material-icons-outlined {
      font-size: 40px;
    }
  }
}

info-box {
  display: flex !important;

  .info-box {
    &.blue {
      background-color: $colour-blue;

      .card-body {
        .card-number {
          color: $colour-white;
        }
      }
    }

    &.white {
      background-color: $colour-white;

      .card-image {
        color: $colour-blue;
      }

      .card-body {
        .card-number {
          color: $colour-black;
        }
      }
    }

    &.no-shadow {
      box-shadow: none;
    }

    .card-image {
      padding-bottom: 0 !important;

      :first-child {
        font-size: 90px;
      }
    }

    .card-body {
      padding-top: 0px !important;
      font-size: 22px;

      .card-number {
        color: $colour-white;
        font-size: 52px;
        font-weight: lighter;
        margin-top: 0px;
        padding-top: 0px;

        .material-icons,
        .material-icons-outlined {
          font-size: 40px;
        }
      }
    }
  }
}

custom-box {
  display: inline-flex;

  .custom-box {
    &.no-shadow {
      box-shadow: none;
    }

    .card-image {
      padding-bottom: 0px !important;
      color: $colour-blue;

      :first-child {
        font-size: 90px;
      }
    }

    .card-body {
      padding-top: 0px !important;
      font-size: 22px;

      .card-number {
        color: $colour-blue;
        font-size: 52px;
        font-weight: lighter;
        margin-top: 0px;
        padding-top: 0px;
      }
    }

    .card-footer {
      .stats {
        i {
          margin-right: 5px;
        }
      }
    }

    &.blue {
      background-color: $colour-blue;
      color: $colour-white;

      .card-image {
        padding-bottom: 0px !important;
        color: $colour-white;

        :first-child {
          font-size: 90px;
        }
      }

      .card-body {
        color: $colour-white !important;

        .card-number {
          .title {
            color: $colour-white;
          }
        }

        .subTitle {

        }
      }

      .card-footer {
        .stats {

        }
      }

    }
  }
}

.filter-container {

  padding: 20px;

  .card-content {

    .form-group {
      margin-top: 0px;

      .find-icon {
        position: absolute;
        margin-top: -30px;
        margin-left: 90%;
      }
    }

  }
}

.projects-container {
  .btn {
    &.full {
      width: 100%;
      font-size: 1rem;

      .material-icons {
        float: right;
      }

    }
  }

  .project-list {

    project-item {
      .card {

        .card-header {
          margin-left: 15px;
        }

        margin-bottom: 35px;
        cursor: pointer;
      }

      .title {
        margin-bottom: 0px;
      }

      .category {
        height: 20px;
        margin-bottom: 0px;
      }

      .gavatar {
        padding-top: 5px;

        .material-icons-outlined {
          font-size: 16px;
          vertical-align: text-top;
        }

        .user {
          margin-right: 5px;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }

  }
}

.project-dashboard {

  .loading-icon {
    text-align: center;

    .material-icons {
      font-size: 100px !important;
      margin: 25px 0 45px 0;
    }
  }

  .percentage {
    position: absolute;
    top: 20%;
    //left: 35%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    line-height: 1;

    .percentage-symbol {
      font-size: 30px;
      text-align: center;
    }
  }

  .features-pie {
    margin-top: -31px;
    display: block;

    ngx-charts-chart {
      display: block;
    }

    .ngx-charts-outer {
      //display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      //margin-left: 20%;
    }

    svg {
      //margin-bottom: 10px;
      //position: relative;
      //left: 25%;
      //-webkit-transform: translateX(-50%);
      //-ms-transform: translateX(-50%);
      //transform: translateX(-50%);
    }
  }

  .coverage-details-box {

    .blue {
      .subTitle {
        .material-icons {
          vertical-align: bottom;
        }

        .occurrence {
          color: $colour-white;
        }
      }

      .card-body {
        .title {
          color: $colour-white;
        }
      }
    }

    .content-loading {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 50px;

      .material-icons {
        font-size: 60px;
      }
    }

    .content {
      .row {
        margin-top: 10px;
      }
    }

    .subTitle {

      .text {
        font-size: 16px;
      }

      .occurrence {
        text-align: center;
        color: $colour-blue;
        font-size: 50px;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .material-icons {
        vertical-align: bottom;
      }
    }

    .coverage-content {
      font-size: 16px;

      .material-icons {
        vertical-align: middle;
        font-size: 6px;
        margin-right: 5px;
      }
    }
  }

}

.users-container {
  .user-card {
    padding-right: 6px;
    padding-left: 6px;
  }

  .buttons-cell-td {
    padding: 8px 0px;
  }

  td {
    .material-icons {
      font-size: 20px;
    }
  }
}

.requirements-container {

  input::placeholder {
    color: $colour-gray-light;
  }

  .section-description {
    margin: 0px;

    .material-icons-outlined {
      line-height: 1.2;
      font-size: 16px;
      vertical-align: text-bottom;
    }
  }

  .section-title {
    .material-icons,
    .material-icons-outlined {
      font-size: 12px;
    }
  }

  .card {
    margin-top: 5px;
    margin-bottom: 20px;

    &.feature-table-card {
      margin-top: 18px;
      margin-bottom: 18px;

      .card-content {
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: scroll;
      }

      td {
        padding: 5px;
        text-align: left;

        &:first-of-type {
          text-align: center;
        }
      }
    }

    .requirements-section {
      margin-top: 10px;

      .requirement-table {
        min-height: 250px;
      }
    }

    .border-label {
      background: #f8f8f8;
      margin-top: 8px;
    }

    .button-remove-feature {
      margin-top: 16px;
    }

    .card-content {
      ::placeholder {
        color: $colour-gray-light;
      }

      .feature-content {
        .feature-identifier {
          background: $colour-white-dark;
          color: $colour-gray;
          font-size: 16px;
          padding-top: 10px;
          padding-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .form-group {
          margin-top: 0px;

          label.control-label {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 1;
            vertical-align: top;
          }

          input.form-control {
            padding-top: 0px;
            padding-bottom: 0px;

            height: 20px;
          }
        }

        .button-col {
          padding-left: 0px;
        }
      }

      .requirement-table {
        min-height: 250px;

        thead {
          cursor: default;

          tr {
            .title {
              font-size: 14px;
            }

            .id {
              width: 8%;
              text-align: center;
            }

            .description {
              width: 90%;
            }

            .buttons {
              width: 2%;
            }
          }
        }

        tbody {
          tr {
            td {
              padding-top: 5px;
              padding-bottom: 5px;
            }

            .id {
              width: 8%;
              text-align: center;
              color: $colour-gray;
              vertical-align: inherit;
            }

            .description {
              width: 90%;
            }

            .buttons {
              width: 2%;
            }

            .form-group {
              margin: 0px;
              padding: 0px;
            }

            input {
              margin: 0px;
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .requirements-document-container {

    .form-group {
      margin-top: -8px;
    }

    .document-toolbar {
      .buttons {
        padding: 0px;

        .btn {
          margin-left: 0px;
        }
      }

      .no-side-padding {
        padding-left: 0;
        padding-right: 0;

        .number-page {
          margin-left: 10px;
        }
      }
    }

    .pdf-container {
      padding-left: 0px;
      overflow: auto;
      height: 95%;
      width: 95%;
    }
  }
}

.modal-content {
  toast-container {
    #toast-container {
      //position: absolute;

    }
  }
}

.button-modal-footer {
  .material-icons {
    font-size: 20px;
    position: relative;
    top: 5px;
  }

  .text {
    font-size: 0.75rem;
    padding-left: 4px;
  }
}

.dark-modal-content {
  .modal-content {
    background-color: $colour-black;
    color: $colour-white;

    input, textarea {
      color: $colour-white;
    }
  }
}

.blue-modal-content {
  .modal-content {
    background-color: $colour-blue;
    color: $colour-white;

    input, textarea {
      color: $colour-white;
    }
  }
}

.plusIcon {
  margin-top: -5px;
  margin-left: 50%;
}

.downloadIcon {
  margin-left: 15%;
}

.totalRow {
  width: 100%;
  text-align: left;
}

.project-modal-container {
  background-color: $colour-black;

  #name {
    color: $colour-white;
  }

  #name:focus {
    color: $colour-white;
  }

  #name::placeholder {
    color: $colour-white;
  }

  #description {
    color: $colour-white;
  }

  #description:focus {
    color: $colour-white;
  }

  #description::placeholder {
    color: $colour-white;
  }
}

.card-info {
  margin-top: 15%;
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #282828;
  font-size: 18px;
  font-weight: normal;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.pull-center {
  float: inherit;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.pointer {
  cursor: pointer;
}

.deny {
  cursor: not-allowed !important;
}

.help {
  cursor: help;
}

.report-container {
  .col-index {
    margin-right: 8px;
  }

  .col-checkbox {
    margin-right: 8px;
  }
}

.hosts-container {
  td {
    .material-icons {
      font-size: 20px;
    }
  }

  .buttons-cell-td {
    padding: 8px 0px;
  }

  .host-container {
    .host-card {
      padding-right: 6px;
      padding-left: 6px;
    }

    .form-group {
      margin-top: 0px;
      margin-left: 7px;
      font-size: 1rem;

      .find-icon {
        position: absolute;
        margin-top: -30px;
        margin-left: 90%;
      }

      .form-control::placeholder {
        color: #bfbfbf;
        font-size: 14px;
      }
    }

    .info {
      font-size: 1rem;
      color: #282828;
    }

    .available-hosts {
      font-size: 1rem;
      margin-top: 6px;

      .available-hosts-numb {
        padding-left: 15px;
        padding-right: 5px;
        color: #1998F7;
      }

      .material-icons {
        color: #1998F7;
        vertical-align: bottom;
      }
    }
  }

  .suts-box {
    padding: 0;

    .even {
      padding-right: 10px;
    }

    .odd {
      padding-left: 10px;
    }

    .suts-card {
      padding-right: 10px;

      .card-header {
        background-color: transparent;
      }

      .card-header-suts {
        font-size: 10px;

        .descr {
          color: #1998F7;
          font-size: 24px;
          vertical-align: bottom;
          margin-top: 10px;
          margin-left: -40px;
        }

        .material-header-icon {
          background-color: #1998F7;
          padding: 10px;
          float: right;
          margin-top: -20px;
          margin-right: -25px;
          font-size: 40px;

          &.black {
            background-color: $colour-black;
          }
        }
      }

      .card-content {
        padding-top: 15px;
      }

      .header-content {
        font-size: 17px;

        .form-group {
          margin-top: 0px;
          margin-left: 7px;

          .find-icon {
            position: absolute;
            margin-top: -30px;
            margin-left: 90%;
          }

          .form-control::placeholder {
            color: #282828;
            font-size: 17px;
          }
        }

        .connected-sut {
          margin-right: 7px;

          .connected-sut-numb {
            padding-left: 15px;
            padding-right: 5px;
            color: #1998F7;
          }

          .material-icons {
            color: #1998F7;
            vertical-align: bottom;
          }
        }

        button {
          padding: 0px;
        }
      }

      .body-content {
        margin: 0 7px;
        padding-top: 15px;

        .table {
          padding: 12px 8px;

          .reachavailability {
            vertical-align: center;
            font-size: 1.063rem;
            text-align: center
          }

          .network {
            position: relative;
            bottom: 3px;
          }

          td {
            color: black
          }
        }
      }
    }
  }
}

.material-icons-lg {
  font-size: 96px;
}

.document-modal-container {
  .upload-file {
    padding-bottom: 10px;
    position: relative;
    margin: 8px 0 0;
  }

  .inputFile {
    margin-top: 9px;
  }
}

.no-padding {
  padding: 0px 0px !important;
}

.no-margin {
  margin: 0px 0px;
}

.scenarios-container {
  .section-description {
    margin: 0px;

    .material-icons-outlined {
      line-height: 1.2;
      font-size: 16px;
      vertical-align: text-bottom;
    }
  }

  .select-feature {
    .background-card {
      margin-top: 20px;
    }

    .card-definition {
      position: relative;
      bottom: 20px;
      font-size: 12px;
    }

    margin: 5px 0;

    .user-story {
      margin-bottom: 10px;
    }

    .feature-name {
      margin-bottom: 10px;
    }

    .form-group {
      margin: 0;
      padding-bottom: 0px;
    }
  }

  .card-content {
    margin-left: 7px;
    margin-right: 7px;

    ::placeholder {
      color: $colour-gray-light;
    }

    .requirement-table {
      .id-cell {
        width: 5%;
      }

      .description-cell {
        width: 95%;
      }
    }

    .scenario-table {
      .id-cell {
        width: 5%;
      }

      .description-cell {
        width: 85%;
      }

      .buttons-cell {
        width: 10%;
      }

      .material-icons-outlined {
        margin: 2px;
      }

      .active {
        background: $colour-white-dark;
      }
    }

    .gherkin-table {
      td {
        padding-top: 0px;
        padding-bottom: 0px;
        vertical-align: middle;
      }

      .keyword-type {
        width: 10%;
      }

      .keyword-description {
        width: 75%;
      }

      .keyword-option {
        width: 15%;
      }
    }

    .requirements-container {

      .material-icons,
      .material-icons-outlined {
        font-size: 16px;
        vertical-align: text-bottom;
      }

      .ng2-tag-input {
        font-size: 12px;
      }

      .jira-info-box {
        margin-top: 10px;

        select {
          height: 20px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
      }
    }

    .scenario-buttons-group {
      margin-top: 50px;
    }
  }

  .scenarioButton {
    font-size: 14px;

    .material-icons-sm {
      font-size: 24px;
      margin-right: 3px;
      float: right;
      padding-right: 0rem;
      padding-left: 2rem;
      padding-bottom: 0rem;
      padding-top: 0rem;
    }
  }

}

.modal-dialog {
  select.form-control:not([size]):not([multiple]) {
    height: 2.3rem;
  }

  .align-content-center {
    align-content: center;
  }

  .modalDialogButton {
    font-size: 14px;

    .material-icons-sm {
      font-size: 24px;
      margin-right: 3px;
      float: right;
      padding-right: 0rem;
      padding-left: 2rem;
      padding-bottom: 0rem;
      padding-top: 0rem;
    }
  }
}

.workspace-container,
.snippets-container,
.test-on-fly-container {
  .section-title {
    margin-left: -10px;
  }

  .card-content {
    margin-left: 7px;
    margin-right: 7px;
  }

  .button-info {
    margin-top: -40px;
    position: relative;
    align-items: end;
  }

  .button-connect-disconnect {
    align-self: center;
  }

  label {
    margin: 0px;
  }

  .snippet-table {
    .id-cell {
      width: 5%;
    }

    .name-cell {
      width: 70%;
    }

    .buttons-cell {
      td {
        color: black;
      }

      width: 20%;
    }
  }

  .keyword-item-container {
    padding-left: 0;
    padding-right: 0;

    .form-group {
      margin-bottom: 10px;
    }

    .code-section {
      border-radius: 0;

      .editor {
        height: 350px;
      }
    }

    input::placeholder {
      color: $colour-gray-light;
    }

    textarea::placeholder {
      color: $colour-gray-light;
    }

    .black-header {
      margin-left: 70%;
      background-color: $colour-black;
      padding-top: 5px;
      padding-bottom: 5px;

      .title {
        font-size: 14px;
      }


    }

    .keyword-title {
      color: $colour-blue;
      font-size: 16px;
      margin-bottom: 5px;

      .material-icons,
      .material-icons-outlined {
        color: $colour-blue;
        cursor: pointer;
      }
    }

    .code-hidden {
      font-size: 12px;
      font-style: italic;
      color: $colour-gray-light;
    }

    p.error-title {
      margin-top: 5px;
      padding-left: 10px;
      margin-bottom: 0px;
      background-color: #ffa21a;
      font-size: 12px;
      font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
      color: $colour-white;
      border-bottom: 1px solid;

      .pull-right {
        height: 20px;

        .material-icons {
          font-size: 12px;
          margin-right: 2px;
          margin-top: 4px;
        }
      }
    }
  }

}

.management-sut-container {
  .form-group {
    margin-top: 0px;
  }

  .card-content {
    margin-left: 5px;
    margin-right: 5px;
  }

  .card-footer {
    margin-left: 5px;
    margin-right: 5px;

    .stats {
      font-size: 0.75rem;

      .material-icons {
        font-size: 20px;
      }
    }
  }

  .variables-choice {
    margin-top: 10px;

    .btn {
      .fa-filter {
        font-size: 18px;
      }
    }

    .material-icons,
    .material-icons-outlined {
      padding-left: 10px;
    }
  }

  .variable-filter {
    padding-top: 0.25rem;
    margin-bottom: 10px;

    .ng2-tag-input {
      padding: 2px 0 0 0 !important;
    }

    .ng2-tag-input__text-input {
      padding: 0;
      height: 32px !important;

    }

    ::placeholder {
      color: $colour-gray-light;
      font-size: 14px;
      font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
    }

    .add-label {
      position: relative;
      font-size: 22px;
      top: 10px;
      right: 5px;
    }
  }


  .variables-section {
    .scroll-height {
      max-height: 477px;
    }

    tbody > tr {
      height: 48px;
    }

    td {
      vertical-align: middle !important;
    }

    .name-col {
      width: 65%;
    }

    .type-col {
      width: 10%
    }

    .buttons-col {
      width: 25%;
    }


    .variable-value {
      font-size: 12px;
      font-style: italic;
      color: #999;
      line-height: 10px;
    }

    .variable-attributes {
      text-align: left;
      padding: 0px 5px;

      .info {
        font-size: 21px;
        padding: 5px 3px;
      }

      .material-icons,
      .material-icons-outlined {
        font-size: 20px;
      }
    }
  }

}

.snippets-container {
  .form-group {
    margin-top: 0px;
  }
}

.graphic-tag {
  .ng2-tag-input {
    padding: 2px 0 0 0 !important;

    [class ^= "ng-tns-" ] {
      font-size: 12px;
      margin: 0 1px;
      height: 32px;
    }
  }

  .ng2-tag-input__text-input {
    padding: 0;
    height: 32px !important;
  }
}

.add-variable-modal {

  .modal-body {
    padding: 0rem 1rem;

    .type {
      margin: 10px 0;
    }

    .form-group {
      margin-top: 0px;
      padding-bottom: 5px;

      .control-label {
        margin-bottom: 0px;
      }

      .form-control {
        padding: 0px;
      }
    }

    .add-attr-button {
      margin-top: 25px;
    }
  }

  .section-title {
    font-size: 14px;
  }

  ::placeholder {
    color: $colour-gray-light;
    font-size: 14px;
    font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
  }
}

.full-screen {
  max-width: 98%;
  margin: 1rem auto;

  .modal-header {
    padding-bottom: 5px;

    .row {
      display: contents !important;
    }
  }

  .modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .modal-footer {
    padding: 0.5rem 1rem;
  }

  .form-group {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;

    .control-label {
      line-height: 1;
      margin-bottom: 0px;
    }

    .form-control {
      padding-top: 0px;
      padding-bottom: 0px;
      line-height: 1;
    }

    ::placeholder {
      color: $colour-gray-light;
      font-size: 14px;
      font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
    }
  }

  .img-responsive {

    display: block;
    max-width: 100%;
    height: auto;
  }

  .image-container {
    .card {
      margin-bottom: 0px;

      .card-content {
        padding-bottom: 0px;
        padding-top: 0px;

        img {
          cursor: pointer;

          &.shown-screen-landscape {
            width: 100% !important;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }

          &.shown-screen-portrait {
            height: 100% !important;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }

          &.hidden-screen {
            //position: absolute;
            margin-left: auto;
            margin-right: auto;
            display: block;
            opacity: 0;
            z-index: 1000;
          }
        }
      }

      .draw-pane {
        .draw-item {
          border: 2px solid $colour-red;
          position: absolute;
          z-index: 999;
        }
      }
    }

    img {
      width: auto;
    }

    .img-container {
      width: auto;
      display: inline;
    }
  }

  .layout-hierarchy-section {
    margin-bottom: 0px;

    .struct-container {
      overflow-y: scroll;

      .layout-item {
        .selected {
          color: $colour-red;
        }
      }

      ul {
        .list-unstyled {
          padding-left: 20px;
          list-style: none;
        }
      }

      .material-icons {
        font-size: 15px;
      }
    }
  }

  .attributes-container {
    margin-top: 10px;
    margin-bottom: 0px;

    .card {
      margin-bottom: 0px;

      td {
        padding: 0.5rem;
      }

      .md-checkbox {
        margin: 0px;

        label:before {
          border-color: $colour-gray;
        }
      }
    }

    .scroll {
      max-height: 100%;
      overflow-y: auto;
    }

    .row {
      margin-top: 20px;
    }
  }
}

.add-bugtrackinguser-modal{
  max-width: 700px;
  .field-icon {
    font-size: 16px;
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
    cursor: pointer;
  }
}

.select-tool-modal{
  max-width: 1100px;
  .modal-body{
    padding: 1rem 1rem;
    .issue{
      margin-bottom: -0.5rem;
    }
  }
}

.new-redmine-modal{
  max-width: 800px;
}


.project-settings-container {

  .info {
    font-size: 16px;
    margin-right: 10px;
    position: relative;
    top: 3px;
    left: 1px;
  }

  .md-checkbox {
    margin-right: 10px;
  }

  .details-section {
    .buttons-row {
      margin-top: 10px;
    }
  }

  .third-party-tools-section {
    select {
      height: 35px !important;
    }

    .field-icon {
      font-size: 16px;
      float: right;
      margin-left: -25px;
      margin-top: -25px;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }

    .buttons-group {
      padding-top: 30px;
    }
  }

  .documentation-section {

    a {
      color: $colour-black;
      vertical-align: baseline;
    }
  }

  .war-alert-box {
    min-height: 31px;

    .war-alert-icon {
      position: relative;
      top: 7px;
      padding: 0 3px;
      color: $colour-red
    }

    .war-alert-icon {
      color: $colour-red
    }
  }
}

.add-document-modal {
  .cancel-upload {
    margin-top: 18px;
  }
}

.lgBtn {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 12px;
  font-weight: bold;

  i {
    float: right;
    margin-left: 30px;

    .material-icons-sm {
      font-size: 10px;
    }
  }

}

.buttons-cell {
  .btn-xs {
    padding: 0px;
    color: $colour-blue;
    float: right;
  }
}

.light {
  .ngx-charts {

    text {
      fill: $colour-black;
    }
  }
}

.dark {
  /**
     * Backgrounds
     */
  //$color-bg-darkest: #13141b;
  $color-bg-darkest: yellow;
  //$color-bg-darker: #1b1e27;
  $color-bg-darker: orange;
  //$color-bg-dark: #232837;
  $color-bg-dark: green;
  $color-bg-med: #2f3646;
  //$color-bg-light: #455066;
  $color-bg-light: red;
  $color-bg-lighter: #5b6882;

  /**
     * Text
     */
  $color-text-dark: #72809b;
  $color-text-med-dark: #919db5;
  $color-text-med: #A0AABE;
  $color-text-med-light: #d9dce1;
  $color-text-light: #f0f1f6;
  $color-text-lighter: #fff;

  .ngx-charts {

    text {
      fill: $colour-blue-light;
    }

    .tooltip-anchor {
      fill: $color-bg-lighter;
      //fill: rgb(255, 255, 255);
    }

    .gridline-path {
      stroke: $colour-blue-darker;
    }

    .refline-path {
      stroke: $color-bg-light;
    }

    .reference-area {
      fill: #fff;
    }

    .line-highlight {
      display: inherit !important;

      .area {
        opacity: 0.15 !important;
      }
    }

    .grid-panel {
      &.odd {
        rect {
          fill: rgba(255, 255, 255, 0.05);
        }
      }
    }

    .force-directed-graph {
      .edge {
        stroke: $color-bg-light;
      }
    }

    .number-card {
      p {
        color: $color-text-light;
      }
    }

    .gauge {
      .background-arc {
        path {
          fill: $color-bg-med;
        }
      }

      .gauge-tick {
        path {
          stroke: $color-text-med;
        }

        text {
          fill: $color-text-med;
        }
      }
    }

    .linear-gauge {
      .background-bar {
        path {
          fill: $color-bg-med;
        }
      }

      .units {
        fill: $colour-white;
      }
    }

    .timeline {
      .brush-background {
        fill: rgba(255, 255, 255, 0.05);
      }

      .brush {
        .selection {
          fill: rgba(255, 255, 255, 0.1);
          stroke: #aaa;
        }
      }
    }

    .polar-chart .polar-chart-background {
      fill: rgb(30, 34, 46);
    }

  }

  .chart-legend {
    .legend-labels {
      background: rgba(255, 255, 255, 0.05) !important;
    }

    .legend-item {
      &:hover {
        color: #fff;
      }
    }

    .legend-label {
      &:hover {
        color: #fff !important;
      }

      .active {
        .legend-label-text {
          color: #fff !important;
        }
      }
    }

    .scale-legend-label {
      color: $color-text-med;
    }
  }

  .advanced-pie-legend {
    color: $color-text-med;

    .legend-item {
      &:hover {
        color: #fff !important;
      }
    }
  }

  .number-card .number-card-label {
    font-size: 0.8em;
    color: $color-text-med;
  }

}

.navbar {
  .btn {
    padding-left: 10px;
    padding-right: 10px;
  }

  .search {
    text-align: right;
    position: relative;
    background-color: transparent;
    border: none;
    margin-top: 3px;
  }

  .container-fluid {

  }
}

.mosaic-modal {
  .mosaic-modal-container {
    .card-body {
      .card {
        .card-header {
          margin-left: 25%;
          padding: 2%;
        }

        .connect-section {
          position: relative;
          bottom: 36px;

          .button-connect {
            padding-bottom: 3px;
          }

          .streams {
            .description {
              font-size: 22px;
            }
          }
        }
      }

      .remote-controller {
        display: flex;
        justify-content: center;

        #led {
          background-color: red;
          width: 28px;
          height: 5px;
          position: absolute;
          top: 4px;
          border-radius: 10px;
          border: solid 1px black;
          visibility: hidden;
        }
      }
    }
  }
}

.mirroring-modal {
  max-width: 1400px;

  .modal-xl {
    max-width: 1400px;

  }
}

.host-modal-container {
  .environment {
    margin: 10px 10px 0px 0px;

    .small {
      font-size: 16px !important;
      vertical-align: inherit;
    }
  }

  .status {
    margin: 10px 10px 0px 0px;

    .small {
      font-size: 16px !important;
      vertical-align: inherit;
    }
  }

  .md-checkbox {
    label {
      vertical-align: baseline !important;
    }
  }

  .md-checkbox-inline {
    margin-right: 10px;
  }
}


.multisuts-modal-container {
  .modal-body {
    .form-group {
      margin: 0;
    }

    .fa {
      position: relative;
      padding: 0 4px;
      font-size: 18px;
      top: 2px;
    }

    .material-icons {
      font-size: 18px;
      top: 4px;
      position: relative;
      padding: 0 3px;
    }

    .alias {
      input::placeholder {
        color: $colour-gray-light;
      }
    }
  }
}

.add-project-modal {
  .environment {
    margin: 10px 10px 0px 0px;

    .small {
      font-size: 16px !important;
      vertical-align: inherit;
    }
  }

  .md-checkbox-inline {
    margin-right: 10px;
  }
}

.md-checkbox {
  position: relative;
  margin: $md-checkbox-margin;
  text-align: left;

  &.md-checkbox-inline {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: inline;
    line-height: $md-checkbox-size;
    vertical-align: top;
    clear: both;
    padding-left: 1px;

    &:not(:empty) {
      //padding-left: $md-checkbox-label-padding;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: $colour-white;
      border: $md-checkbox-border-width solid $colour-black;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      transition: background .3s;
      margin-top: 3px;
    }

    &:after {
      // checkmark
    }
  }

  input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;

    &:checked {
      + label:before {
        background: $colour-blue;
        border: none;
      }

      + label:after {

        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate($md-checkbox-padding, ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)) rotate(-45deg);
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;

        border: $md-checkmark-width solid $colour-white;
        border-top-style: none;
        border-right-style: none;

        margin-top: 3px;
      }
    }

    &:disabled {
      + label:before {
        background-color: $colour-gray-lighter;
        //border-color: $md-checkbox-border-color-disabled;
        border-color: #d3d3d3;
      }

      &:checked {
        + label:before {
          background: $colour-gray;
        }
      }
    }
  }
}

.test-design-modal-container {
  .management {
    height: 10px;
  }

  .section-title {
    font-size: 14px;
  }

  ::placeholder {
    color: $colour-gray-light;
    font-size: 14px;
    font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
  }

  .md-checkbox {
    width: max-content;

    label {
      color: $colour-black;

      &:before, &:after {
        top: -2px;
      }
    }
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .test-composition {
    .material-icons-outlined, .material-icons {
      vertical-align: middle;
    }

    .accordion-content {
      .card-header {
        background: $colour-black;
      }

      .zoom {
        font-size: 22px;
      }

    }

    .card {
      .card-header {
        padding: 5px 10px;
      }

      .card-body {
        padding: 0px;

        .message {
          padding: 5px;
        }

        .panel-heading {
          margin-left: 20%;
        }

        .test-feature {
          margin-top: 3px;
          margin-bottom: 3px;
          border-bottom: 1px $colour-gray-lighter solid;

          .test-feature-name {
            margin-left: 5px;
            margin-right: 5px;
            color: $colour-gray-light;
          }

          .test-scenario-name {
            margin-left: 5px;
          }

          .delete-test-feature {
            cursor: pointer;
            font-size: 18px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}

fa-icon {
  font-size: 16px;
  padding: 0 5px;
}

.design-container {
  .test-card {
    padding-right: 6px;
    padding-left: 6px;
  }

  .scroll-height {
    max-height: 400px;
  }

  .pagination-sm {
    margin-bottom: 0px;
  }

  .table-body {
    //margin-right: -28px;
    td {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  .fa-filter {
    font-size: 18px !important;
  }

  .testdesign-filter {
    margin: 0 -15px;
    //padding-top: 0.25rem;
    margin-top: 10px;
    //height: 25px;
    //position: relative;
    //bottom: 10px;

    .filter-button {
      padding: 9px 10px 9px 10px;
    }

    ::placeholder {
      color: $colour-gray-light;
      font-size: 14px;
      font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
    }


  }

  .table {

    .name-cell {
      width: 38%;
    }

    .author-cell {
      width: 13%;
    }

    .creation-cell {
      width: 13%;
    }

    .modification-cell {
      width: 13%;
    }

    .buttons-cell {
      width: 10%;
      padding-right: 15px;
    }

    .details-cell {
      padding: 10px 10px;
      width: 13%;
    }

    .buttons-cell-td {
      width: 10%;
      //padding: 8px 0px;
      .btn {
        padding: 0;
      }
    }

    td {
      .material-icons, .material-icons-outlined {
        font-size: 20px;
        padding: 0 4px;
      }
    }
  }

  .card-test-design {
    .card-test-design-header {
      margin-left: 50%;

      .title {
        padding-right: 20px;
      }

      .material-icons-outlined {
        cursor: pointer;
      }
    }

    .priority {
      font-size: 1rem;
    }

    .test-buttons {
      position: relative;
      top: -20px;

      .material-icons {
        margin-left: 5px;
      }

      .manage-button {
        width: 130px !important;

        span {
          vertical-align: middle;
        }
      }

      .war-alert-box {
        min-height: 31px;

        .war-alert-icon {
          position: relative;
          top: 7px;
          padding: 0 3px;
          color: $colour-red
        }

        .war-alert-icon {
          color: $colour-red
        }
      }
    }


    .card-test-design-content {

      .test-list {
        list-style: none;
        margin: 0px;
        padding: 0px;


        > li {
          background-color: $colour-white;
          padding: 0px;
          margin: 0px 0px 20px;
          position: relative;
          display: block;
          width: 100%;
          height: 120px;

          .position {
            display: inline-block;
            color: $colour-white;
            background-color: $colour-blue;
            //padding: 5px;
            text-align: center;
            text-transform: uppercase;
            width: 25px;
            float: left;
            height: 100%;

            > span {
              display: none;
            }

            .drag-tab {
              color: $colour-white-darker;
              display: block;
              transform: rotate(90deg);
              //margin-left: -4px;

              &:hover {
                color: $colour-white;
              }
            }

            .number {
              display: block;
              font-size: 14pt;
              font-weight: 100;
              line-height: 1;
              //padding-top: 5px;
              //position: absolute;
              //bottom: 0;

              .btn-group.position-group  {
                display: block !important;
                margin: 0px;

                .position-button {
                  padding: 0px !important;
                }
              }
            }
          }

          > .info {
            .angle {
              width: 53px;
              position: absolute;
              right: 0;
              top: 0;
            }

            .angle-text {
              position: absolute;
              right: 0px;
              top: 0px;
              color: $colour-white;

              .dropdown-menu {
                overflow: auto;
                position: relative;
                max-height: 115px;
              }
            }

            background-color: #d5eeff;
            overflow: hidden;
            padding-top: 5px;
            position: relative;
            height: 120px;
            text-align: left;
            padding-right: 45px;

            > ul {
              display: table;
              list-style: none;
              margin: 10px 0px 0px;
              padding: 0px;
              width: 100%;
              text-align: center;
              position: absolute;
              left: 0px;
              bottom: 0px;

              > li {
                display: table-cell;
                //cursor: pointer;
                font-size: 11pt;
                font-weight: 300;
                padding: 3px 0px;
              }
            }

            .scenario-name {
              font-size: 0.875rem;
              font-weight: 700;
              margin: 0px;
              padding: 0px 10px;
              overflow: auto;
              max-height: 65px;
            }

            .feature-name {
              font-size: 0.750rem;
              font-weight: 300;

              margin: 0px;
              padding: 0px 10px;
            }

            .expected {
              font-size: 8pt;
              margin: 0px;
              padding: 0px 10px;
              bottom: 0px;
              position: absolute;

              .text {
                bottom: 3px;
                position: relative;
              }

              .material-icons {
                font-size: 0.750rem;
                margin-left: 5px;
              }
            }
          }

          .menu {
            position: absolute;
            bottom: 0px;
            right: 0px;
            display: block;
            width: 40px;

            > ul {
              display: table;
              list-style: none;
              margin: 10px 0px 0px;
              padding: 0px;
              width: 100%;
              text-align: center;
              border-left: 1px solid $colour-white-darker;

              li {
                padding-bottom: 5px;

                > span {
                  font-size: 18px;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }

      .requirement-box {
        font-size: 100%;

        .badge {
          margin-left: 5px;
        }
      }

    }
  }

  .test-design-header {
    padding-bottom: 20px;

    .priority-box {
      height: 1.25rem;

      .priority {
        top: 6px;
        color: $colour-black;
      }

      .form-control {
        margin-top: 6px;
        margin-left: -25px;
        height: 1.25rem;
      }
    }

    .bot-checkbox {
      margin-top: -7px;
    }

    .details-checkbox {
      height: 30px;

      .md-checkbox label::before, .md-checkbox label::after {
        top: -2px;
      }

      label {
        padding-left: 7px;
        color: $colour-black;
      }
    }

    .notifications-drop {
      height: 1.25em;
      background-color: transparent;
      margin-top: 10px;

      .drop-label {
        font-size: 14px;
        padding: 0;
        margin-bottom: -7px;
      }
    }

    .feature-management-drop {
      height: 1.25em;
      background-color: transparent;
      margin-top: 10px;

      .drop-label {
        font-size: 14px;
        padding: 0;
        margin-bottom: -7px;
      }
    }
  }

  .test-design-content {

    .test-item {

      .card-header {
        background-color: $colour-black;
        padding-top: 5px;
        height: 35px;

        &:hover {
          .drag-tab {
            opacity: 1;
          }
        }

        .drag-tab {
          float: left;
          position: relative;
          top: 1px;
          opacity: 0.3;
        }

        .fa {
          font-size: 18px;
          position: relative;
          top: 3px;
          left: 11px;
          color: $colour-blue-darker;
        }

      }

      .card-body {
        padding: 0.7rem 10px;

        .info-alert-icon {
          position: relative;
          top: 7px;
          padding: 0 3px;
          color: $colour-orange
        }

        .info-alert-text {
          color: $colour-orange
        }
      }

      .scenarios-box {
        .even {
          padding-right: 5px;
        }

        .odd {
          padding-left: 5px;
        }

        .scenario-box {
          background-color: $colour-blue-lighter;
          color: $colour-gray;
          padding: 0px 10px;
          height: 110px;
          margin-top: 5px;
          margin-bottom: 5px;

          .md-checkbox {
            //width: max-content;
            label {
              padding-left: 5px;
              color: $colour-black;

              &:before, &:after {
                top: -2px;
              }
            }
          }

          .selected {
            padding: 2px;
          }

          .scenario-selected {

            label {
              color: $colour-black;
              margin-right: 10px;
            }
          }

          .expected-result-box {
            padding-top: 8px;

            label {
              color: $colour-black;
              margin-right: 3px;
            }

            .material-icons {
              font-size: 16px;
              vertical-align: text-bottom;
            }
          }
        }
      }

      .card-footer {
        border-top: none;

        .title {
          margin-right: 5px;
        }

        .requirement-box {
          font-size: 100%;

          .badge {
            margin-right: 5px;
          }
        }

        .btn-black {
          padding: 5px 15px;
        }
      }
    }
  }
}

.parameter-modal-container {
  .test-item {
    .card-header {
      padding: 5px 10px;

      .desc {
        .material-icons-outlined {
          font-size: 18px;
        }
      }
    }
  }
}

.dnd-sortable-drag {
  .test-item {
    .card-header {
      background-color: $colour-gray-light !important;
    }
  }
}

.alert-info-box {
  margin: 3px 0px;
  background-color: #d5eeff !important;
  padding: 7px 7px;
  color: #0072c7;

  span {
    vertical-align: middle;
  }

  i {
    vertical-align: middle;
    font-size: 18px;
    margin-right: 3px;
  }
}

.logoLoading {
  display: block;
  margin-top: 10%;
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  -webkit-animation: rotate-scale-up-ver 3s linear infinite both;
  animation: rotate-scale-up-ver 3s linear infinite both;
}

@-webkit-keyframes rotate-scale-up-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
    transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(1) rotateY(180deg);
    transform: scale(1) rotateY(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(360deg);
    transform: scale(1) rotateY(360deg);
  }
}

.modal-content {

  .schedule-container {

    input:disabled {
      background-color: #f5f5f5;
    }

    .ng-select-disabled {
      .ng-select-container {
        cursor: not-allowed !important;
      }
    }

    .font-14 {
      font-size: 14px !important;
    }

    .bs-chevron-up {
      top: 12px;
    }

    .bs-chevron-down {
      top: -15px;
    }

    timepicker {
      td {
        right: -10px;
      }
    }

    .scheduler-badge {
      padding-top: 5px;
    }

    .schedule-body {
      padding: 0 20px;

      .pointer-disabled {
        cursor: not-allowed !important;
      }

      .days {
        .md-checkbox {
          label {
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }

      .repetition {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        .repeat-label {
          margin-left: -25px;
        }

        input {
          width: 52px;
        }

        .repeat-p {
          padding-bottom: 10px;
        }
      }

      .card {
        align-items: center;
      }

      .time {
        align-self: center;
        margin: auto;

        .start-time-box {
          padding-left: 15px;
        }

        p {
          font-size: 14px;
        }

        table {
          tr {
            td {
              .form-group {
                position: relative !important;
                right: -10px !important;
              }
            }
          }
        }

        .bg-faded {
          color: $colour-black;

          th {
            button {
              background-color: $colour-blue-light;
            }
          }
        }
      }

    }
  }

  .modal-footer {

    .material-icons {
      font-size: 14px;
    }

    .refresh {
      padding: 0;
      margin: 0;
      font-size: 0.75rem;

      .material-icons {
        font-size: 20px;
      }
    }
  }
}

.execution-container {
  .card-header {
    .fa-cogs {
      font-size: 22px;
      padding-top: 2px;
    }
  }

  .card-body {
    padding: .9375rem 10px;

    .nav-link {
      padding: 2px 60px;
    }

    .pipeline-table {
      td {
        padding: 1px 3px;
      }

      .name-cell {
        width: 68%;
      }

      .sut-involved-cell {
        width: 20%;

        .material-icons {
          font-size: 20px;
        }
      }

      .button-cell {
        width: 12%;
      }
    }

    .test-execution-pane {
      .table-body {
        width: 101.5%;
      }

      .row {
        padding-bottom: 3px;
      }

      .scroll-height {
        height: 650px;
      }

      .test-suite-explorer {
        .material-icons {
          position: relative;
          top: 6px;
        }
      }

      .test-suite-list-pane {
        .table-body {
          width: 101.8%;
        }

        .number-cell {
          width: 3%;
        }

        .name-cell {
          width: 67%;
        }

        .creation-cell {
          width: 15%;
        }

        .button-cell {
          width: 15%;
        }
      }

      ::placeholder {
        color: $colour-gray-light;
      }

      border-top: solid 2px $colour-blue;
      padding-top: 5px;

      .name-filter {
        margin-left: 25px;
        width: 95%;
        margin-bottom: 7px;
      }

      .icon-filter {
        opacity: 1;

        span {
          margin: 7px;
        }
      }

      .fa-filter {
        font-size: 18px;
        top: 12px;
        left: 14px;
        position: absolute;
      }

      .table {
        td {
          padding: 1px 3px;
        }

        .checkbox-cell {
          width: 2%;
          padding-left: 0px;

          .md-checkbox {
            margin: 0;
            height: 7px;
            bottom: 6px;
          }
        }

        .name-cell {
          width: 38%;
        }

        .author-cell {
          width: 17%;
        }

        .modification-cell {
          width: 13%;
        }

        .buttons-cell {
          width: 10%;
          padding-right: 22px;
        }

        .details-cell {
          .material-icons {
            font-size: 20px;
          }

          //padding: 3px 10px;
          width: 20%;
        }
      }

    }

    .test-design-details-box {

      .test-design-selector {
        &.col-md-10 {
          padding-right: 0px;
        }
      }

      .test-design-border {
        border-bottom: 2px solid $colour-blue;
      }

      .form-control {
        padding: 0;
      }

      .test-design-details-expander {
        padding-left: 0px;

        .inner-btn {
          background-color: $colour-blue;
          display: block;
          height: 30px;
          top: 37px;
          position: relative;
          padding-right: 15px;

          &.no-border {
            height: 27px;
          }

          .extend {
            color: $colour-white;
          }
        }
      }

      .box-info {
        border-bottom: 2px solid $colour-blue;
        margin-left: 0px !important;
        margin-right: 0px !important;

        margin-bottom: 5px;

        .result {
          position: relative;
          bottom: 8px;
        }

        .lab-text {
          position: relative;
          top: -3px;
        }

        .material-icons {
          font-size: 20px;
        }
      }
    }

    .hosts-pane {

      .alert-info-box {
        margin: 8px 0;
      }

      .btn-black {
        padding: 4px 10px;
      }

      .card-host-pane {
        margin-top: 30px !important;

        .card-header {
          background-color: $colour-black;
          //padding: 5px 15px;
        }

        .card-body {
          padding: 0px 5px;

          .host-unavailable {
            color: $colour-gray-light;
            cursor: pointer;

            .material-icons-outlined {
              font-size: 14px;
              vertical-align: middle;
            }
          }

          .env-pane {
            .section-title {
              background-color: transparent;
              border-top: none;
              border-bottom: 1px solid $colour-black;
              margin: 0 0 2px;
            }

            .material-icons,
            .material-icons-outlined {
              font-size: 20px;
              position: relative;
              top: 3px;
              margin-right: 3px;
            }

          }
        }

        .scrollable {
          overflow-x: auto;
          max-width: 80%;
        }

        .available {
          padding-right: 5px;
          position: relative;
          top: -10px;

          .ar-bility {
            position: relative;
            top: -3 px;
          }

          .material-icons {
            padding-right: 15px;
            padding-left: 4px;
            font-size: 14px;
            vertical-align: middle;
          }
        }
      }
    }

    .back-button {
      position: relative;
      top: -20px;

      button {
        .fa-cogs {
          font-size: 16px;
          padding-top: 1px;
        }
      }
    }

    .test-execution-item {
      .section-title {
        .title {
          .material-icons {
            position: relative;
            top: 4px;
          }
        }
      }
    }
  }

  .card-footer {
    margin: 0;

    .repetition {
      font-size: 0.75rem;

      input {
        width: 30px;
      }

      .repetitions-symbol {
        font-size: 20px;
        position: relative;
        bottom: -5px;
      }
    }
  }

  .sut-pane {
    padding: 0px 6px;
    margin: 5px 0px 5px 0px;

    .subtitle {
      font-size: 10px;
      margin: 0 0 0 10px;
    }

    .info {
      //&:hover {
      //  border: 2px solid black;
      //  //background-color: #d5eeff !important;
      //}
      .angle {
        width: 35px;
        position: absolute;
        right: 0;
        top: 0;
      }

      .fa {
        position: absolute;
        font-size: 40px;
        //opacity: 0.6;
        right: 14px;
        color: white;
        bottom: 10px;
      }

      .material-icons {
        position: absolute !important;
        font-size: 40px !important;
        //opacity: 0.6;
        right: 4px;
        color: white !important;
        top: 50px !important;
      }

      background-color: $colour-gray-lighter;
      overflow: hidden;
      padding-top: 5px;
      position: relative;
      height: 100px;
      text-align: left;
      padding-right: 45px;

      > ul {
        display: table;
        list-style: none;
        margin: 10px 0px 0px;
        padding: 0px;
        width: 100%;
        text-align: center;
        position: absolute;
        left: 0px;
        bottom: 0px;

        > li {
          display: table-cell;
          cursor: pointer;
          font-size: 11pt;
          font-weight: 300;
          padding: 3px 0px;
        }
      }

      .sut {
        position: relative;

        .sut-info {
          font-size: 0.875rem;
          font-weight: 700;
          margin: 0px;
          padding: 0px 10px;
        }

        .sut-name {
          font-size: 10pt;
          font-weight: 300;
          line-height: 10px;
          margin: 0px;
          padding: 0px 10px;
        }
      }

      .menu {
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: block;
        width: 40px;

        > ul {
          display: table;
          list-style: none;
          margin: 10px 0px 0px;
          padding: 0px;
          width: 100%;
          text-align: center;
          //border-left: 1px solid $colour-white-darker;

          li {
            padding-bottom: 5px;

            > span {
              font-size: 18px;
              vertical-align: middle;
            }
          }
        }
      }

    }

    .tags-selected {

      position: relative;
      z-index: 10;
      top: 24px;

      ul {
        padding: 0 10px;
        font-size: 12px;

        li {
          font-size: 12px;
          display: inline;
          padding: 2px 3px;
        }
      }
    }

    .tags-list {
      padding: 0 5%;
      font-size: 12px;
      position: absolute;
      width: 100%;
      bottom: 0px;

      ng-multiselect-dropdown {
        border: none;

        .multiselect-dropdown {
          background-color: transparent;
          border: transparent;

          .dropdown-btn {
            border: none;
            padding: 0;

            .selected-item {
              background-color: transparent;
              font-size: 12px;
              color: $colour-black;
              padding: 0;
              border: none;

              a {
                color: $colour-black !important;
              }
            }
          }

          .dropdown-list {
            .no-data {
              h5 {
                font-size: 14px !important;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }

    //.menu {
    //  position: absolute;
    //  bottom: 0px;
    //  right: 17px;
    //  .md-checkbox{
    //    margin: 0;
    //  }
    //display: block;
    //width: 40px;

    //> ul {
    //  display: table;
    //  list-style: none;
    //  margin: 10px 0px 0px;
    //  padding: 0px;
    //  width: 100%;
    //  text-align: center;
    //  border-left: 1px solid $colour-white-darker;
    //
    //  li {
    //    padding-bottom: 5px;
    //
    //    > span {
    //      font-size: 18px;
    //      vertical-align: middle;
    //    }
    //  }
    //}
    //}

    .sut-pane-content {
      background-color: $colour-white-dark;
      padding: 3px 5px;

      .material-icons {
        //font-size: 14px !important;
      }

      .md-checkbox {
        margin: 0px;
      }

      .subtitle {
        font-size: 12px;
      }

      .availability {
        position: absolute;
        right: 0%;
      }

      .form-check-sign {
        padding: 0;
      }
    }
  }

  .test-cart-pane {
    margin-top: 30px;

    .material-icons,
    .material-icons-outlined {
      font-size: 20px;
      vertical-align: text-bottom;
    }
  }
}

//.test-on-fly-container {
//  .button-connect-disconnect {
//    margin-top: 35px;
//  }
//  .snippet-table{
//    .id-cell{
//      width: 5%;
//    }
//    .name-cell{
//      width: 70%;
//    }
//    .buttons-cell{
//      td{
//        color:black;
//      }
//      width: 20%;
//    }
//  }
//}

.calendar-content-container {
  padding: 0 15px;
  margin-top: 15px;
  color: $colour-blue-darker;
}

.hook-container {
  table {
    td {
      padding: 5px 0px;
    }

    .md-checkbox {
      margin: 0px;
    }
  }

  .api-token {
    overflow-x: auto;
    width: 200px;
    display: flex;
  }

  .material-icons,
  .material-icons-outlined {
    font-size: 20px;
  }

  .regenerate-token {
    vertical-align: -webkit-baseline-middle;
  }

  .hook-save-btn {
    margin-top: 25px;
  }
}

.scheduled-test-cart {
  .section-title {
    margin-top: 10px;
    margin-bottom: 4px;
  }

  .sut-pane {
    padding: 10px 8px;
    margin: 5px;
    background-color: $colour-white-dark;

    .sut-pane-content {
      .subtitle {
        font-size: 12px;
      }

      .material-icons-outlined {
        font-size: 16px;
        padding-right: 3px;
        position: relative;
        top: 3px;
      }
    }
  }
}

.cal-cell {
  &:hover {
    background-color: $colour-blue-lighter !important;
  }

  .cal-day-badge {
    border-radius: 0 !important;
    background-color: $colour-blue !important;
  }
}


.modal-schedule {
  max-width: 1050px;

  .bs-datepicker {
    box-shadow: none;
    //margin: 0 50%;
    .bs-datepicker-container {
      padding: 0;

      .bs-calendar-container {

        .bs-datepicker-head {
          background-color: $colour-blue !important;
        }

        .bs-datepicker-body {
          tbody {
            tr {
              .week {
                span {
                  color: $colour-blue !important;
                }
              }

              td {
                .selected {
                  background-color: $colour-blue !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.report-container {
  .card-container {
    .card-body {
      .btn-black {
        padding: 5px 5px;
      }

      .nav-link {
        padding: 2px 60px;
      }

      .report-filter {
        ::placeholder {
          color: $colour-gray-light;
          font-size: 14px;
          font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
        }

        .filter-button {
          padding: 9px 10px 9px 10px;
        }
      }

      .test-execution-container {
        margin-bottom: 30px;

        .details-pane {
          border-top: solid 2px $colour-blue;
          padding-top: 5px;

          .item-value {
            font-size: 20px;
          }

          .alert-info-box {
            top: 0px;
          }

          .requirements {
            td {
              padding-top: 0px;
              padding-bottom: 0px;
            }
          }

          .material-icons-outlined {
            position: relative;
            top: 4px;
            font-size: 20px;
          }
        }

        .details-pane-content {
          .table {
            .index {
              width: 2%;
            }

            td {
              padding: 0;
            }

            .col-scenarios {
              width: 8%;
            }

            .col-verdict {
              width: 2%;
            }

            .col-name {
              width: 41%;
              padding-right: 5px;
              padding-left: 5px;
            }

            .col-host {
              width: 10%;
            }

            .col-date {
              width: 10%;
            }

            .col-features {
              width: 5%;
            }

            .col-details {
              width: 7%;
            }

            .col-buttons {
              width: 3%;
            }

            .col-suts {
              width: 10%;

              .fa {
                position: relative;
                top: 2px;
              }
            }

            .col-checkbox {
              width: 2%;
              padding-left: 0px;

              .md-checkbox {
                margin: 0;
              }
            }

            .material-icons {
              position: relative;
              font-size: 20px;
            }

            .material-icons-outlined {
              font-size: 20px;
              padding: 0 4px;
            }
          }
        }
      }
    }
  }
}

.opacity {
  opacity: 0.4;
}

.dashboard-test-container {
  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }

  .execution-instance-pane {
    .card-header-icon {
      .card-image {
        padding-bottom: 0px;

        .material-icons-outlined {
          font-size: 90px;
          color: $colour-blue;
        }

        .planned-execution {
          display: block;
          float: left;
          background-color: $colour-gray-lighter;
          border-radius: 50%;
          color: $colour-blue-darker;
          font-size: 12px;
          width: 24px;
          height: 24px;
          text-align: center;
        }
      }
    }

    .card-body {
      padding-top: 0px;
    }

    hr {
      margin-bottom: 5px;
    }

    .feature-pane {
      .title {
        font-size: 12px;
        text-align: right;
        color: $colour-gray-light;
      }

      .current {
        font-size: 13px;

        .material-icons {
          font-size: 13px;
        }
      }

      .last {
        font-size: 12px;
        color: $colour-gray;

        .material-icons {
          font-size: 12px;
        }
      }
    }

    .scenario-pane {
      .title {
        font-size: 12px;
        text-align: right;
        color: $colour-gray-light;
      }

      .current {
        font-size: 13px;

        .material-icons {
          font-size: 13px;
        }
      }

      .last {
        font-size: 12px;
        color: $colour-gray;

        .material-icons {
          font-size: 12px;
        }
      }
    }

    .category {
      font-weight: bold;
      color: $colour-gray;
      margin-bottom: 0.625rem;
    }

    .sub-category {
      font-size: 12px;
      color: $colour-gray-light;
      margin: 0px;
      line-height: 1;
    }

    .test-execution-pane {
      text-align: left;

      .material-icons {
        font-size: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }

      .execution-author-info,
      .execution-time-info {
        font-size: 12px;

        .test-name {
          border-bottom: 1px solid $colour-black;
        }
      }

      .progress {
        margin-bottom: 5px;
      }

      .feature {
        .progress-bar {
          background-color: $colour-red;
        }
      }

      .scenario {
        .progress-bar {
          background-color: $colour-orange;
        }
      }
    }

    .card-footer {
      min-height: 36px;
      display: inline-block;
    }
  }
}

.test-report-details-container {
  margin-right: 15px;
  margin-left: 15px;

  .card-body {
    .assessment {
      top: 4px;
      position: relative;
    }

    .btn-black {
      width: 150px;

      .fa {
        font-size: 16px;
        margin-right: 3px;
        color: $colour-blue;
      }
    }

    .fa {
      font-size: 24px;
      margin-right: 3px;
    }

    .sut-desc {
      font-size: 20px;
      margin-left: 5px;
    }

    .feat-desc {
      margin-left: 5px;
    }

    .scenario-container {
      margin: 0;
      border-top: 1px solid $colour-blue;

      .scenario-details {
        margin-top: 20px;

        .scenario-detail-container {
          .material-icons {
            position: relative;
            top: 3px;
            margin-right: 4px;
          }
        }

      }

      .title {
        font-size: 20px;

        .material-icons,
        .material-icons-outlined {
          margin-right: 5px;
        }

      }

      .table {
        td {
          padding: 0;
        }
      }
    }

    .scenario-footer {
      margin-bottom: 15px;

      .requirement-tag {
        padding: 0 10px;
        margin: 0 5px;
      }
    }

    .material-icons {
      font-size: 18px;
    }
  }
}


.screen-modal-container {
  pre {
    background-color: $colour-black;
    color: $colour-white
  }
}

.radius {
  border-radius: 50%;
}

.bg-success-colour {
  color: $colour-white !important;
  background-color: $colour-green !important;
}

.bg-code-colour {
  background-color: #1e1e1e !important;
}

.bg-danger-colour {
  color: $colour-white !important;
  background-color: $colour-red !important;
}

.bg-warning-colour {
  color: $colour-white !important;
  background-color: $colour-orange !important;
}

.bg-blue-colour {
  color: $colour-white !important;
  background-color: $colour-blue !important;
}

.bg-black-colour {
  color: $colour-white !important;
  background-color: $colour-black !important;
}

.grey-colour {
  color: $colour-gray-light !important;
}

.screen-modal-container {
  img {
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
}

.pagination-sm {
  .page-link {
    padding: 0.25rem 10px;
    font-size: .875rem;
    line-height: 1.5;
    border: solid 1px darkgrey;
    border-right: none;
  }

  .pagination-last {
    border-right: solid 1px darkgrey;
  }
}

.floater {
  top: auto;
  right: 55px;
  bottom: 20px;
  left: auto;
  position: fixed;
  z-index: 10;
}

.up-index {
  z-index: 10;
}

.button-page {
  width: 90px !important;
  height: 30px !important;

  span {
    vertical-align: middle;
  }
}

.popover {
  h3 {
    font-size: 18px;
    text-align: center;
  }

  .popover-body {
    background-color: $colour-white;

    dt {
      font-size: 15px;
      color: $colour-black;
    }

    dd {
      font-size: 13px;
      color: $colour-black;
    }
  }
}

.led {
  position: inherit;
  vertical-align: middle;
  font-size: 14px !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  background-image: none;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 55px;
  color: #1998F7;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #1998F7;
}

.modal-manual {
  .manual-container {
    .card-manual {
      .card-body {
        .feature-scenario-manual {
          margin-bottom: 8px;
        }

        .sut-manual-info {
          position: absolute;
          top: -10px;
        }

        .manual-title {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .parameters {
          margin-right: 20px;
        }

        .manual-btn {
          margin: 0.5px 0;
          padding: 0 20px;
        }

        .table > tbody > tr {
          position: inherit !important;
          border-bottom-style: none !important;
          border-bottom-width: 0px !important;
        }

        .step-table {
          .step-name-sec {
            max-width: 350px;
          }
        }
      }
    }
  }
}

.manual-modal{
  max-width: 1140px !important;
}


.issue-modal {
  max-width: 1100px !important;

  .card-issue-modal {
    .card-body {
      .spinner-border{
        margin-left: 50%;
        margin-top: 5%;
        margin-bottom: 5%;
        width: 4rem;
        height: 4rem;
      }

      .card-form {
        #description.form-control {
          padding-bottom: 9px;
          height: 200px !important;
        }

        .name-form {
          padding-right: 55px !important;
          button:focus{
            outline: none;
          }
        }

        .switcher {
          position: absolute;
          margin-top: -30px;
          margin-left: 91%;
          background: none;
          border: none;
          cursor: pointer;
        }

        .load {
          position: absolute;
          margin-top: -30px;
          margin-left: 84%;
          background: none;
          border: none;
          cursor: pointer;
        }

        .suts {
          overflow-y: auto;
          max-height: 400px;
          padding-top: 1rem;
        }

        .requirement-tag {
          padding: 0 10px;
          margin: 0 5px;
        }

        .col-md-1 {
          padding-top: 16px;
        }

        .col-md-2 {
          padding-top: 16px;
        }

        .col-md-3 {
          padding-top: 16px;
        }

        .col-md-4 {
          padding-top: 16px;
        }

        .col-md-5 {
          padding-top: 16px;
        }

        .col-md-6 {
          padding-top: 16px;
        }

        .col-md-7 {
          padding-top: 16px;
        }

        .col-md-8 {
          padding-top: 16px;
        }

        .col-md-9 {
          padding-top: 16px;
        }

        .col-md-10 {
          padding-top: 16px;
        }

        .col-md-11 {
          padding-top: 16px;
        }

        .col-md-12 {
          padding-top: 16px;
        }
      }
    }

    .timeline {
      position: relative;
      margin: 10px auto;
      padding: 10px 0;
      width: 1000px;
      box-sizing: border-box;
    }

    .timeline:before {
      content: '';
      position: absolute;
      left: 50%;
      width: 2px;
      height: 100%;
      background: #c5c5c5;
    }

    .timeline ul {
      padding: 0;
      margin: 0;
    }

    .timeline ul li {
      list-style: none;
      position: relative;
      width: 50%;
      padding: 20px 40px;
      box-sizing: border-box;
    }

    .timeline ul li:nth-child(odd) {
      float: left;
      text-align: right;
      clear: both;
    }

    .timeline ul li:nth-child(even) {
      float: right;
      text-align: left;
      clear: both;
    }

    .content {
      //padding-bottom:20px;
      .property {
        position: relative;
        top: 4px;
        font-size: 18px;
      }
    }

    .timeline ul li:nth-child(odd):before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 24px;
      right: -6px;
      background: $colour-blue;
      border-radius: 50%;
      box-shadow: 0 0 0 3px $colour-blue-lighter;
    }

    .timeline ul li:nth-child(even):before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 24px;
      left: -4px;
      background: $colour-blue;
      border-radius: 50%;
      box-shadow: 0 0 0 3px $colour-blue-light;
    }

    .timeline ul li h3 {
      padding: 0;
      margin: 0;
      color: $colour-blue;
      font-weight: 600;
    }

    .timeline ul li p {
      margin: 10px 0 0;
      padding: 0;
    }

    .timeline ul li .time h4 {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }

    .timeline ul li:nth-child(odd) .time {
      position: absolute;
      top: 12px;
      right: -140px;
      margin: 0;
      padding: 8px 16px;
      background: $colour-blue;
      color: #fff;
      border-radius: 18px;
      box-shadow: 0 0 0 3px $colour-blue-lighter;
    }

    .timeline ul li:nth-child(even) .time {
      position: absolute;
      top: 12px;
      left: -140px;
      margin: 0;
      padding: 8px 16px;
      background: $colour-blue;
      color: #fff;
      border-radius: 18px;
      box-shadow: 0 0 0 3px $colour-blue-lighter;
    }

    @media(max-width: 1000px) {
      .timeline {
        width: 100%;
      }
    }
    @media(max-width: 767px) {
      .timeline {
        width: 100%;
        padding-bottom: 0;
      }
      .timeline:before {
        left: 20px;
        height: 100%;
      }
      .timeline ul li:nth-child(odd),
      .timeline ul li:nth-child(even) {
        width: 100%;
        text-align: left;
        padding-left: 50px;
        padding-bottom: 50px;
      }
      .timeline ul li:nth-child(odd):before,
      .timeline ul li:nth-child(even):before {
        top: -18px;
        left: 16px;
      }
      .timeline ul li:nth-child(odd) .time,
      .timeline ul li:nth-child(even) .time {
        top: -30px;
        left: 50px;
        right: inherit;
      }
    }
  }

}

.bugtracking-container {

  .form-group {
    ng-select{
      width: 11rem;
    }

    input{
      width: 15rem;
    }
    input::placeholder{
      color:$colour-gray-lighter;
    }
    .date-bugtracking {
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      //border-radius: .25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      background-image: none;
    }

    .date-bugtracking::placeholder {
      color: #999;
    }
  }

  .spinner-border{
    margin-left: 50%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 4rem;
    height: 4rem;
  }

}


.theme-dark-blue .bs-datepicker-head {
  background-color: #1998F7;
}

.theme-dark-blue .bs-datepicker-body table td span.selected,
.theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*="select-"]:after,
.theme-dark-blue .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #1998F7;
}

.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}

.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  background-color: #1998F7;
}

.testManuallyNotes {
  .form-control::placeholder {
    color: #999;
  }
}

.scroll-view {
  overflow-y: scroll;
}

.modal-labels {
  .modal-header {
    span {
      font-size: 14px;
    }
  }

  .modal-body {
    .label-table {
      margin-top: 15px;
    }

    .add-label {
      position: relative;
      top: 10px;
      right: 5px;
    }
  }

  table {
    .name-cell {
      padding-top: 0px;
      padding-bottom: 0px;
      width: 80%;
    }

    .action-cell {
      padding-top: 0px;
      padding-bottom: 0px;
      width: 20%;
    }
  }
}

.ng2-dropdown-menu {
  z-index: 1050 !important;
}

.ng-select .ng-select-container {
  border-radius: 0px !important;
}

.ng2-tag-input {
  padding: 2px 0 0 0 !important;

  [class ^= "ng-tns-" ] {
    font-size: 12px;
    margin: 0 1px;
    height: 32px;
  }
}

.ng2-tag-input__text-input {
  padding: 0;
  height: 32px !important;
}

.sut-selected {
  background-color: #d5eeff !important;

  .fa {
    color: white !important;
  }

  .material-icons {
    color: white !important;
  }
}

.book-container {

  .card-test-design-header {
    background-color: black;
  }

  .test-book {
    .fa-filter {
      font-size: 18px !important;
    }

    .testbook-filter {
      //margin: 0 -15px;
      //padding-top: 0.25rem;
      //margin-top: 10px;
      //height: 25px;
      //position: relative;
      //bottom: 10px;

      .filter-button {
        padding: 7px 10px 7px 10px;
      }

      ::placeholder {
        color: $colour-gray-light;
        font-size: 14px;
        font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
      }
    }

    .scroll-height {
      max-height: 250px;
    }

    .table {
      margin-top: 2px;

      td {
        padding: 1px 3px;
      }

      .name-cell {
        width: 40%;
      }

      .author-cell {
        width: 20%;
      }

      .modification-cell {
        width: 15%;
      }

      .creation-cell {
        width: 15%;
      }

      .buttons-cell {
        width: 10%;
        padding-right: 10px;

        button {
          margin-right: 3px;

        }
      }
    }
  }

  .card-test-book {

    .filter-test-book-design {
      margin-top: -15px;
      margin-left: 15px;
    }

    ::placeholder {
      color: $colour-gray-light;
    }

    .war-alert-box {
      margin-top: -20px;
      margin-left: 30px;

      .war-alert-icon {
        color: #dc4632;
      }
    }

    .filter {
      margin-top: 10px;

      .name-filter {
        margin-left: 25px;
        width: 95%;
        margin-bottom: 7px;
      }

      .icon-filter {
        opacity: 1;

        span {
          margin: 7px;
        }
      }

      .fa-filter {
        font-size: 18px;
        top: 12px;
        left: 14px;
        position: absolute;
      }
    }

    .test-design {
      margin-top: 5px;

      .head {
        padding-right: 15px !important;
      }

      .scroll-height {
        max-height: 250px;
      }

      .table {
        margin-top: 2px;

        td {
          padding: 1px 3px;
        }

        .checkbox-cell {
          width: 2%;
          padding-left: 0px;

          .md-checkbox {
            margin: 0;
            height: 7px;
            bottom: 6px;
          }
        }

        .name-cell {
          width: 65%;
        }

        .author-cell {
          width: 25%;
        }

        .button-cell {
          width: 8%;

        }
      }
    }

    .current-design-list {
      margin-top: 5px;

      .head {
        padding-right: 15px !important;
      }

      .scroll-height {
        max-height: 150px;
      }

      .table {
        margin-top: 2px;

        td {
          padding: 1px 3px;
        }

        .position-cell {
          width: 2%;
        }

        .name-cell {
          width: 68%;
        }

        .author-cell {
          width: 25%;
        }

        .buttons-cell-td {
          width: 5%;
        }
      }
    }

    .card-footer {
      border: none;

    }
  }
}


.book-modal {
  max-width: 700px !important;

  .modal-xl {
    max-width: 700px !important;
  }

  .name-test-book {
    margin-bottom: 15px;
  }

  .author-test-book {
    margin-bottom: 15px;
  }

  .description-test-book {
    margin-bottom: 15px;
  }

  .design-test-book {
    margin-bottom: 15px;

    .scroll-height {
      max-height: 250px;

      .table {
        margin-top: 2px;

        td {
          padding: 1px 3px;
        }

        .checkbox-cell {
          width: 2%;
          padding-left: 0px;

          .md-checkbox {
            margin: 0;
            height: 7px;
            bottom: 6px;
          }
        }

        .name-cell {
          width: 100%;
          font-family: "Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif;
          font-size: 14px;
        }

        .author-cell {
          width: 17%;
        }

        .modification-cell {
          width: 13%;
        }

        .buttons-cell {
          width: 10%;
          padding-right: 22px;
        }

        .details-cell {
          width: 20%;

          .material-icons {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.head {
  padding-right: 30px !important;
}

.custom-switch {
  top: 25%;
  left: 4%;

  .custo-control-label {
    padding-top: 2px;
  }

  label {
    color: $colour-gray-light;
    font-size: 14px;
  }
}

.analytics-section {
  height: 545px;
}

.center {
  display: block;
  margin: auto;
}

.modal-variable {
  .modal-header {
    .graphical-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .capture {
      float: right;
      margin-left: 20px;
    }
  }

  .modal-body {
    .displayCol {
      display: block;
      background-color: var(--bgcolorval);
    }

    .colorval {
      height: 100%;
      border: 1px solid black;
      border-radius: 100%;
    }

      .row {
        .remote-controller {
          justify-content: center;
          #led {
            background-color: red;
            width: 28px;
            height: 5px;
            position: absolute;
            top: 0.5%;
            left: 45%;
            border-radius: 10px;
            border: solid 1px black;
            visibility: hidden;
          }
        }

        .stream {
          .video {
            .view-button {
              position: relative;
              bottom: 44px;
            }
      //
      //      .card-header {
      //        margin-left: 25%;
      //        padding: 2%;
      //      }
      //
      //      .card-body {
      //        min-height: 100%;
      //
      //        video {
      //          min-height: 100%;
      //        }
      //      }
      //    }
      //
      //    .tools {
      //      .btn-blue {
      //        padding-top: 3.2px !important;
      //        padding-bottom: 3.2px !important;
      //        text-align: left;
      //      }
      //
      //      .bkg-blue {
      //        background-color: $colour-blue;
      //      }
          }
      //
      }
    }
  }
}

.user-modal{
  max-width: 850px;

  .portfolio-section {
    select {
      height: 35px !important;
    }

    .field-icon {
      font-size: 16px;
      float: right;
      margin-left: -25px;
      margin-top: -25px;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }

    .buttons-group {
      padding-top: 30px;
    }
  }
}

.hideData {
  display: none;
}

.displayData {
  display: block;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: inherit;
}

.pop-book {

  .material-icons-outlined {
    padding: 0 4px;
    font-size: 20px;
  }
}

.icon-bar {
  width: 38px; /* Set a specific width */
  background-color: $colour-blue; /* Dark-grey background */
  .bkg-blue {
    &:disabled {
      background-color: $colour-blue;
    }

    background-color: $colour-blue;
    display: block; /* Make the links appear below each other instead of side-by-side */
    text-align: center; /* Center-align text */
    padding: 5px; /* Add some padding */
    transition: all 0.3s ease; /* Add transition for hover effects */
    .material-icons {
      font-size: 27px !important;
      background-color: $colour-blue !important;
      color: white !important;
    }
  }
}

