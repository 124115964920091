$colour-white: #fff;
$colour-white-dark: #f8f8f8;
$colour-white-darker: #e7e7e7;
$colour-black: #282828;
$colour-gray-lighter: #d2d2d2;
$colour-gray-light: #999;
$colour-gray: #555555;
$colour-green: #A0B428;
$colour-green-darker: #3e7520;
$colour-red: #DC4632;
$colour-red-darker: #e90d00;
$colour-orange: #f0ad4e;
$colour-orange-darker: #df8a13;
$colour-blue: #1998F7;
$colour-blue-darker: #076fbd;
$colour-blue-light: #9fd5fb;
$colour-blue-lighter: #E8F5FE;

$md-checkbox-margin: 1em 0;
$md-checkbox-size: 1.25em;
$md-checkbox-padding: .25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.125em;
$md-checkbox-label-padding: .75em;

@mixin transition($time) {
  -webkit-transition: all $time ease;
  -moz-transition: all $time ease;
  -ms-transition: all $time ease;
  -o-transition: all $time ease;
  transition: all $time ease;
}